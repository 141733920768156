import React from 'react';
import {COLORS, LOADING_MESSAGE, LoadingIndicator, Placeholder, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';
import District from 'app/models/District';

const SCSection = styled.section`
  width: 100%;
  display: flex;
  background-color: ${COLORS.lightestGrey};
  justify-content: center;
  ${props => props.theme.desktopLayout`
    justify-content: center;
  `};
`;

const PromoHeadline = styled(Typography)`
  &&& {
    padding-bottom: 1.25rem;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1112px;
  padding: 2rem 1rem;
`;

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.75rem;
`;

const Item = styled.div`
  flex-basis: 9.375rem;
`;

const SCPlaceholder = styled(Placeholder)`
  &&& {
    background-color: transparent;
  }
`;
const Regions = ({regions, isFetching, error}) => {
  let content;
  if (error) {
    content = <SCPlaceholder>Beim Laden ist ein Fehler aufgetreten.</SCPlaceholder>;
  } else if (isFetching) {
    content = <LoadingIndicator message={LOADING_MESSAGE.loadingData} bgColor={false} />;
  } else {
    const regionLinks = regions.map(region => {
      return (
        <Item key={region.slug}>
          <LinkOnComponent to={new District(region.slug).getRootUrl()}>
            <Typography variant={'body1'}>{region.name}</Typography>
          </LinkOnComponent>
        </Item>
      );
    });

    content = <Container>{regionLinks}</Container>;
  }

  return (
    <SCSection>
      <Content>
        <PromoHeadline variant='headline2'>Unsere Hauptregionen</PromoHeadline>
        {content}
      </Content>
    </SCSection>
  );
};

export {Regions};
